<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.07485 1.75C4.80647 1.75 5.39956 2.30964 5.39956 3V5.75L19.1006 5.75V3C19.1006 2.30964 19.6937 1.75 20.4253 1.75C21.1569 1.75 21.75 2.30964 21.75 3V21C21.75 21.6904 21.1569 22.25 20.4253 22.25C19.6937 22.25 19.1006 21.6904 19.1006 21V18.25H5.39956V21C5.39956 21.6904 4.80647 22.25 4.07485 22.25C3.34322 22.25 2.75013 21.6904 2.75013 21V17.0176C2.75004 17.0117 2.75 17.0059 2.75 17C2.75 16.9941 2.75004 16.9883 2.75013 16.9824V7.01758C2.75004 7.01173 2.75 7.00587 2.75 7C2.75 6.99413 2.75004 6.98827 2.75013 6.98242V3C2.75013 2.30964 3.34322 1.75 4.07485 1.75ZM5.39956 8.25V10.75L19.1006 10.75V8.25L5.39956 8.25ZM19.1006 13.25L5.39956 13.25V15.75L19.1006 15.75V13.25Z"
    />
  </svg>
</template>
